import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import { slugify } from '../utils/Misc';
const faqs = [
  {
    title: 'What is an Event Organiser? what they do? ',
    content:
      'An organiser will host a tournament, they will have a system to control and manage the tournament, they can hire streamers, on this panel E-Warz will also have a sponsor section as well, which can show interest in any tournament, this if both parties agree sponsors can financially support any tournament.',
  },
  {
    title: 'How do I find or join a tournament?',
    content:
      'The players or team leader or manager can create the team in his platform, they can look for any tournament where the team can take part in, once they have signed up for the tournament, the organisers can schedule the matches and the information will be passed to the member who will be part of that match.',
  },
  {
    title: 'Who is a Team Manger?',
    content:
      'A team manager/ Team leader will have a overview on all tournaments they have took part in, they will see how they are doing as team, what are the future tournaments they can take party in, also by seeming the recent matches they can make important discussions on the team and the are which needs improvement.',
  },
  {
    title: 'What is a Commentator and what do they do?',
    content:
      'A commentator will be the online state host in other words this person will be sports caster who provides commentary during a live tournament event, commentator will usually work either the streamer in synchronisation to deliver the best experience to the audience.',
  },
];
const Terms = () => {
  return (
    <Layout>
      <Helmet title="Terms and Conditions | E-Warz Tournaments" />
      <section className="clean-block features bg-dark-alt text-white pb-lg-5">
        <div className="container">
          <div className="block-heading">
            <h1 className="text-info">Terms and Conditions</h1>

            {/* <p>
              following are the 
            </p> */}
          </div>
          <div>
            <p class="c8">
              <span class="c0">1. </span>
              <span class="c3 c0">Introduction</span>
            </p>
            <p class="c8">
              <span class="c0">Welcome to </span>
              <span class="c0 c6">IYG Entertainment Private Limited</span>
              <span class="c2 c0">.</span>
            </p>
            <p class="c8">
              <span class="c0 c6">IYG Entertainment Private Limited</span>
              <span class="c0">
                &nbsp;(&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates{' '}
              </span>
              <span class="c7 c6">
                <a class="c4" href="#">
                  ewarz.org
                </a>
              </span>
              <span class="c0">&nbsp;(here in after referred to as </span>
              <span class="c0 c6">&ldquo;Service&rdquo;</span>
              <span class="c2 c0">).</span>
            </p>
            <p class="c8">
              <span class="c0">Our Privacy Policy governs your visit to </span>
              <span class="c6 c7">
                <a class="c4" href="#">
                  ewarz.org
                </a>
              </span>
              <span class="c2 c0">
                &nbsp;and explains how we collect, safeguard and disclose information that results
                from your use of our Service.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We use your data to provide and improve Service. By using Service, you agree to the
                collection and use of information under this policy. Unless otherwise defined in
                this Privacy Policy, the terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions.
              </span>
            </p>
            <p class="c1">
              <span class="c0">Our Terms and Conditions (</span>
              <span class="c0 c6">&ldquo;Terms&rdquo;</span>
              <span class="c0">
                ) govern all use of our Service and together with the Privacy Policy constitute your
                agreement with us (
              </span>
              <span class="c0 c6">&ldquo;Agreement&rdquo;</span>
              <span class="c2 c0">).</span>
            </p>
            <p class="c8">
              <span class="c0">2. </span>
              <span class="c3 c0">Definitions</span>
            </p>
            <p class="c8">
              <span class="c0 c6">SERVICE</span>
              <span class="c0">&nbsp;means the </span>
              <span class="c7">
                <a class="c4" href="#">
                  ewarz.org
                </a>
              </span>
              <span class="c2 c0">
                &nbsp;website operated by IYG Entertainment Private Limited.
              </span>
            </p>
            <p class="c1">
              <span class="c0 c6">PERSONAL DATA</span>
              <span class="c2 c0">
                &nbsp;means data about a living individual who can be identified from those data (or
                from those and other information either in our possession or likely to come into our
                possession).
              </span>
            </p>
            <p class="c1">
              <span class="c0 c6">USAGE DATA</span>
              <span class="c2 c0">
                &nbsp;is data collected automatically either generated by the use of Service or from
                the Service infrastructure itself (for example, the duration of a page visit).
              </span>
            </p>
            <p class="c1">
              <span class="c0 c6">COOKIES</span>
              <span class="c2 c0">
                &nbsp;are small files stored on your device (computer or mobile device).
              </span>
            </p>
            <p class="c1">
              <span class="c0 c6">DATA CONTROLLER</span>
              <span class="c2 c0">
                &nbsp;means a natural or legal person who (either alone or jointly or in common with
                other persons) determines the purposes for which and how any personal data are or
                are to be, processed. For this Privacy Policy, we are a Data Controller of your
                data.
              </span>
            </p>
            <p class="c1">
              <span class="c0 c6">DATA PROCESSORS (OR SERVICE PROVIDERS)</span>
              <span class="c2 c0">
                &nbsp;mean any natural or legal person who processes the data on behalf of the Data
                Controller. We may use the services of various Service Providers to process your
                data more effectively.
              </span>
            </p>
            <p class="c1">
              <span class="c0 c6">DATA SUBJECT</span>
              <span class="c2 c0">
                &nbsp;is any living individual who is the subject of Personal Data.
              </span>
            </p>
            <p class="c1">
              <span class="c0 c6">THE USER</span>
              <span class="c2 c0">
                &nbsp;is the individual using our Service. The User corresponds to the Data Subject,
                who is the subject of Personal Data.
              </span>
            </p>
            <p class="c8">
              <span class="c0">3. </span>
              <span class="c3 c0">Information Collection and Use</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We collect several different types of information for various purposes to provide
                and improve our Service to you.
              </span>
            </p>
            <p class="c8">
              <span class="c0">4. </span>
              <span class="c3 c0">Types of Data Collected</span>
            </p>
            <p class="c1">
              <span class="c3 c0">Personal Data</span>
            </p>
            <p class="c1">
              <span class="c0">
                While using our Service, we may ask you to provide us with certain personally
                identifiable information that can be used to contact or identify you (
              </span>
              <span class="c0 c6">&ldquo;Personal Data&rdquo;</span>
              <span class="c2 c0">
                ). Personally, identifiable information may include, but is not limited to:
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.1. Email address</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.2. First name and last name</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.3. Phone number</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.4. Address, Country, State, Province, ZIP/Postal code, City
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.5. Cookies and Usage Data</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.6 &nbsp;Verification documents (ID proof and Pan card)</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We may use your Data to contact you with newsletters, marketing or promotional
                materials and other information that may be of interest to you. You may opt-out of
                receiving any, or all, of these communications from us by following the unsubscribe
                link.
              </span>
            </p>
            <p class="c1">
              <span class="c3 c0">Usage Data</span>
            </p>
            <p class="c1">
              <span class="c0">
                We may also collect information that your browser sends whenever you visit our
                Service or when you access the Service by or through any device (
              </span>
              <span class="c0 c6">&ldquo;Usage Data&rdquo;</span>
              <span class="c2 c0">).</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                This Usage Data may include information such as your computer&rsquo;s Internet
                Protocol address (e.g. IP address), browser type, browser version, the pages of our
                Service that you visit, the time and date of your visit, the time spent on those
                pages, unique device identifiers and other diagnostic data.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                When you access Service with a device, this Usage Data may include information such
                as the type of device you use, your device unique ID, the IP address of your device,
                your device operating system, the type of Internet browser you use, unique device
                identifiers and other diagnostic data.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">&nbsp;</span>
            </p>
            <p class="c1">
              <span class="c3 c0">Location Data</span>
            </p>
            <p class="c1">
              <span class="c0">We will use and store information about (</span>
              <span class="c0 c6">&ldquo;Location Data&rdquo;</span>
              <span class="c2 c0">
                ). We use this data to provide features of our Service and to improve and customize
                our Service.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">&nbsp;</span>
            </p>
            <p class="c1">
              <span class="c3 c0">Tracking Cookies Data</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We use cookies and similar tracking technologies to track the activity on our
                Service and we hold certain information.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Cookies are files with a small amount of data which may include an anonymous unique
                identifier. Cookies are sent to your browser from a website and stored on your
                device. Other tracking technologies are also used such as beacons, tags and scripts
                to collect and track information and to improve and analyse our Service.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                You can instruct your browser to refuse all cookies or to indicate when a cookie is
                being sent. However, if you do not accept cookies, you may not be able to use some
                portions of our Service.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">Examples of Cookies we use:</span>
            </p>
            <p class="c1">
              <span class="c0">0.1. </span>
              <span class="c0 c6">Session Cookies:</span>
              <span class="c2 c0">&nbsp;We use Session Cookies to operate our Service.</span>
            </p>
            <p class="c1">
              <span class="c0">0.2. </span>
              <span class="c0 c6">Preference Cookies:</span>
              <span class="c2 c0">
                &nbsp;We use Preference Cookies to remember your preferences and various settings.
              </span>
            </p>
            <p class="c1">
              <span class="c0">0.3. </span>
              <span class="c0 c6">Security Cookies:</span>
              <span class="c2 c0">&nbsp;We use Security Cookies for security purposes.</span>
            </p>
            <p class="c1">
              <span class="c0">0.4. </span>
              <span class="c0 c6">Advertising Cookies:</span>
              <span class="c2 c0">
                &nbsp;Advertising Cookies are used to serve you with advertisements that may be
                relevant to you and your interests.
              </span>
            </p>
            <p class="c1">
              <span class="c3 c0">Other Data</span>
            </p>
            <p class="c8">
              <span class="c2 c0">
                While using our Service, we may also collect the following information: sex, age,
                date of birth, place of birth, passport details, citizenship, registration at the
                place of residence and actual address, telephone number (work, mobile), details of
                documents on education, qualification, professional training,
              </span>
            </p>
            <p class="c8">
              <span class="c2 c0">&nbsp;</span>
            </p>
            <p class="c8">
              <span class="c2 c0">&nbsp;</span>
            </p>
            <p class="c8">
              <span class="c0">5. </span>
              <span class="c3 c0">Use of Data</span>
            </p>
            <p class="c8">
              <span class="c2 c0">
                IYG Entertainment Private Limited uses the collected data for various purposes:
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.1. to provide and maintain our Service;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.2. to notify you about changes to our Service;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.3. to allow you to participate in interactive features of our Service when you
                choose to do so;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.4. to provide customer support;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.5. to gather analysis or valuable information so that we can improve our Service;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.6. to monitor the usage of our Service; AND Pay-outs of any winning amount
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.7. to detect, prevent and address technical issues;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.8. to fulfil any other purpose for which you provide it;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.9. to carry out our obligations and enforce our rights arising from any contracts
                entered into between you and us, including for billing and collection;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.10. to provide you with notices about your account and/or subscription, including
                expiration and renewal notices, email instructions, etc.;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.11. to provide you with news, special offers and general information about other
                goods, services and events which we offer that are similar to those that you have
                already purchased or enquired about unless you have opted not to receive such
                information;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.12. in any other way we may describe when you provide the information;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.13. for any other purpose with your consent.</span>
            </p>
            <p class="c8">
              <span class="c0">6. </span>
              <span class="c3 c0">Retention of Data</span>
            </p>
            <p class="c1">
              <span class="c0 c2">
                We will retain your Data only for as long as is necessary for the purposes set out
                in this Privacy Policy. We will retain and use your Data to the extent necessary to
                comply with our legal obligations (for example, if we are required to retain your
                data to comply with applicable laws), resolve disputes, and enforce our legal
                agreements and policies.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We will also retain Usage Data for internal analysis purposes. Usage Data is
                generally retained for a shorter period, except when this data is used to strengthen
                the security or to improve the functionality of our Service, or we are legally
                obligated to retain this data for longer periods.
              </span>
            </p>
            <p class="c8">
              <span class="c0">7. </span>
              <span class="c3 c0">Transfer of Data</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Your information, including Personal Data, may be transferred to &ndash; and
                maintained on &ndash; computers located outside of your state, province, country or
                other governmental jurisdiction where the data protection laws may differ from those
                of your jurisdiction.
              </span>
            </p>
            <p class="c8">
              <span class="c2 c0">
                If you are located outside India and choose to provide information to us, please
                note that we transfer the data, including Personal Data, to India and process it
                there.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Your consent to this Privacy Policy followed by your submission of such information
                represents your agreement to that transfer.
              </span>
            </p>
            <p class="c8">
              <span class="c2 c0">
                IYG Entertainment Private Limited will take all the steps reasonably necessary to
                ensure that your data is treated securely and following this Privacy Policy and no
                transfer of your Personal Data will take place to an organisation or a country
                unless there are adequate controls in place including the security of your data and
                other personal information.
              </span>
            </p>
            <p class="c8">
              <span class="c2 c0">&nbsp;</span>
            </p>
            <p class="c8">
              <span class="c0">8. </span>
              <span class="c3 c0">Disclosure of Data</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We may disclose personal information that we collect, or you provide:
              </span>
            </p>
            <p class="c1">
              <span class="c0">0.1. </span>
              <span class="c3 c0">Disclosure for Law Enforcement.</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Under certain circumstances, we may be required to disclose your Data if required to
                do so by law or in response to valid requests by public authorities.
              </span>
            </p>
            <p class="c1">
              <span class="c0">0.2. </span>
              <span class="c3 c0">Business Transaction.</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                If we or our subsidiaries are involved in a merger, acquisition or asset sale, your
                Personal Data may be transferred.
              </span>
            </p>
            <p class="c1">
              <span class="c0">0.3. </span>
              <span class="c3 c0">Other cases. We may disclose your information also:</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.3.1. to our subsidiaries and affiliates;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.3.2. to contractors, service providers, and other third parties we use to support
                our business;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.3.3. to fulfil the purpose for which you provide it;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.3.4. to include your company&rsquo;s logo on our website;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.3.5. for any other purpose disclosed by us when you provide the information;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.3.6. with your consent in any other cases;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.3.7. if we believe disclosure is necessary or appropriate to protect the rights,
                property, or safety of the Company, our customers, or others.
              </span>
            </p>
            <p class="c8">
              <span class="c0">9. </span>
              <span class="c3 c0">Security of Data</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                The security of your data is important to us but remember that no method of
                transmission over the Internet or method of electronic storage is 100% secure. While
                we strive to use commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </span>
            </p>
            <p class="c8">
              <span class="c0">10. </span>
              <span class="c3 c0">
                Your Data Protection Rights Under General Data Protection Regulation (GDPR)
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                If you are a resident of the European Union (EU) and European Economic Area (EEA),
                you have certain data protection rights, covered by GDPR.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We aim to take reasonable steps to allow you to correct, amend, delete, or limit the
                use of your Personal Data.
              </span>
            </p>
            <p class="c8">
              <span class="c0">
                If you wish to be informed of what Personal Data we hold about you and if you want
                it to be removed from our systems, please email us at{' '}
              </span>
              <span class="c6 c10">support@ewarz.freshdesk.com</span>
              <span class="c2 c0">.</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                In certain circumstances, you have the following data protection rights:
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.1. the right to access, update or delete the information we have on you;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.2. the right of rectification. You have the right to have your information
                rectified if that information is inaccurate or incomplete;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.3. The right to object. You have the right to object to our processing of your
                Personal Data;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.4. The right of restriction. You have the right to request that we restrict the
                processing of your personal information;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.5. the right to data portability. You have the right to be provided with a copy of
                your Personal Data in a structured, machine-readable and commonly used format;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.6. the right to withdraw consent. You also have the right to withdraw your consent
                at any time where we rely on your consent to process your personal information;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Please note that we may ask you to verify your identity before responding to such
                requests. Please note, we may not able to provide Service without some necessary
                data.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                You have the right to complain to a Data Protection Authority about our collection
                and use of your Personal Data. For more information, please contact your local data
                protection authority in the European Economic Area (EEA).
              </span>
            </p>
            <p class="c8">
              <span class="c0">11. </span>
              <span class="c3 c0">
                Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                CalOPPA is the first state law in the nation to require commercial websites and
                online services to post a privacy policy. The law&rsquo;s reach stretches well
                beyond California to require a person or company in the United States (and
                conceivable the world) that operates websites collecting personally identifiable
                information from California consumers to post a conspicuous privacy policy on its
                website stating exactly the information being collected and those individuals with
                whom it is being shared and to comply with this policy.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">According to CalOPPA, we agree to the following:</span>
            </p>
            <p class="c1">
              <span class="c2 c0">0.1. users can visit our site anonymously;</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.2. our Privacy Policy link includes the word &ldquo;Privacy&rdquo;, and can easily
                be found on the home page of our website;
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.3. users will be notified of any privacy policy changes on our Privacy Policy
                Page;
              </span>
            </p>
            <p class="c8">
              <span class="c0">
                0.4. users can change their personal information by emailing us at{' '}
              </span>
              <span class="c10 c6">support@ewarz.freshdesk.com</span>
              <span class="c2 c0">.</span>
            </p>
            <p class="c1">
              <span class="c2 c0">Our Policy on &ldquo;Do Not Track&rdquo; Signals:</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We honour Do Not Track signals and do not track plant cookies, or use advertising
                when a Do Not Track browser mechanism is in place. Do Not Track is a preference you
                can set in your web browser to inform websites that you do not want to be tracked.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                You can enable or disable Do Not Track by visiting the Preferences or Settings page
                of your web browser.
              </span>
            </p>
            <p class="c8">
              <span class="c0">12. </span>
              <span class="c3 c0">
                Your Data Protection Rights under the California Consumer Privacy Act (CCPA)
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                If you are a California resident, you are entitled to learn what data we collect
                about you, ask to delete your data and not to sell (share) it. To exercise your data
                protection rights, you can make certain requests and ask us:
              </span>
            </p>
            <p class="c1">
              <span class="c3 c0">
                0.1. What personal information do we have about you? If you make this request, we
                will return to you:
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.0.1. The categories of personal information we have collected about you.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.0.2. The categories of sources from which we collect your personal information.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.0.3. The business or commercial purpose for collecting or selling your personal
                information.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.0.4. The categories of third parties with whom we share personal information.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.0.5. The specific pieces of personal information we have collected about you.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.0.6. A list of categories of personal information that we have sold, along with
                the category of any other company we sold it to. If we have not sold your personal
                information, we will inform you of that fact.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                0.0.7. A list of categories of personal information that we have disclosed for a
                business purpose, along with the category of any other company we shared it with.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Please note that you are entitled to ask us to provide this information up to two
                times in a rolling twelve-month period. When you make this request, the information
                provided may be limited to the personal information we collected about you in the
                previous 12 months.
              </span>
            </p>
            <p class="c1">
              <span class="c3 c0">
                0.2. To delete your personal information. If you make this request, we will delete
                the personal information we hold about you as of the date of your request from our
                records and direct any service providers to do the same. In some cases, deletion may
                be accomplished through de-identification of the information. If you choose to
                delete your personal information, you may not be able to use certain functions that
                require your personal information to operate.
              </span>
            </p>
            <p class="c1">
              <span class="c3 c0">
                0.3. To stop selling your personal information. We don&rsquo;t sell or rent your
                personal information to any third parties for any purpose. We do not sell your
                personal information for monetary consideration. However, under some circumstances,
                a transfer of personal information to a third party, or within our family of
                companies, without monetary consideration may be considered a &ldquo;sale&rdquo;
                under California law. You are the only owner of your Data and can request disclosure
                or deletion at any time.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                If you submit a request to stop selling your personal information, we will stop
                making such transfers.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Please note, if you ask us to delete or stop selling your data, it may impact your
                experience with us, and you may not be able to participate in certain programs or
                membership services which require the usage of your personal information to
                function. But in no circumstances, we will discriminate against you for exercising
                your rights.
              </span>
            </p>
            <p class="c8">
              <span class="c0">
                To exercise your California data protection rights described above, please send your
                request(s) by email:{' '}
              </span>
              <span class="c10 c6">support@ewarz.freshdesk.com</span>
              <span class="c2 c0">.</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Your data protection rights, described above, are covered by the CCPA, short for the
                California Consumer Privacy Act. To find out more, visit the official California
                Legislative Information website. The CCPA took effect on 01/01/2020.
              </span>
            </p>
            <p class="c8">
              <span class="c0">13. </span>
              <span class="c3 c0">Service Providers</span>
            </p>
            <p class="c1">
              <span class="c0">
                We may employ third-party companies and individuals to facilitate our Service (
              </span>
              <span class="c0 c6">&ldquo;Service Providers&rdquo;</span>
              <span class="c2 c0">
                ), provide Service on our behalf, perform Service-related services or assist us in
                analysing how our Service is used.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                These third parties have access to your Personal Data only to perform these tasks on
                our behalf and are obligated not to disclose or use it for any other purpose.
              </span>
            </p>
            <p class="c8">
              <span class="c0">14. </span>
              <span class="c3 c0">Analytics</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We may use third-party Service Providers to monitor and analyse the use of our
                Service.
              </span>
            </p>
            <p class="c8">
              <span class="c0">15. </span>
              <span class="c0 c3">CI/CD tools</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We may use third-party Service Providers to automate the development process of our
                Service.
              </span>
            </p>
            <p class="c8">
              <span class="c0">16. </span>
              <span class="c3 c0">Behavioural Remarketing</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We may use remarketing services to advertise on third-party websites to you after
                you visited our Service. We and our third-party vendors use cookies to inform,
                optimise and serve ads based on your past visits to our Service.
              </span>
            </p>
            <p class="c8">
              <span class="c0">17. </span>
              <span class="c3 c0">Payments</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We may provide paid products and/or services within Service. In that case, we use
                third-party services for payment processing (e.g. payment processors).
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We will not store or collect your payment card details. That information is provided
                directly to our third-party payment processors whose use of your personal
                information is governed by their Privacy Policy. These payment processors adhere to
                the standards set by PCI-DSS as managed by the PCI Security Standards Council, which
                is a joint effort of brands like Visa, Mastercard, American Express and Discover.
                PCI-DSS requirements help ensure the secure handling of payment information.
              </span>
            </p>
            <p class="c8">
              <span class="c0">18. </span>
              <span class="c3 c0">Links to Other Sites</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                Our Service may contain links to other sites that are not operated by us. If you
                click a third-party link, you will be directed to that third party&rsquo;s site. We
                strongly advise you to review the Privacy Policy of every site you visit.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We have no control over and assume no responsibility for the content, privacy
                policies or practices of any third-party sites or services.
              </span>
            </p>
            <p class="c8">
              <span class="c0">19. </span>
              <span class="c3 c0">Children&rsquo;s Privacy</span>
            </p>
            <p class="c1">
              <span class="c0">
                Our Services are not intended for use by children under the age of 18 (
              </span>
              <span class="c0 c6">&ldquo;Child&rdquo;</span>
              <span class="c0">&nbsp;or </span>
              <span class="c0 c6">&ldquo;Children&rdquo;</span>
              <span class="c2 c0">).</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We do not knowingly collect personally identifiable information from children under
                18. If you become aware that a Child has provided us with Personal Data, please
                contact us. If we become aware that we have collected Personal Data from Children
                without verification of parental consent, we take steps to remove that information
                from our servers.
              </span>
            </p>
            <p class="c8">
              <span class="c0">20. </span>
              <span class="c3 c0">Changes to This Privacy Policy</span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We may update our Privacy Policy from time to time. We will notify you of any
                changes by posting the new Privacy Policy on this page.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                We will let you know via email and/or a prominent notice on our Service before the
                change becomes effective and update the &ldquo;effective date&rdquo; at the top of
                this Privacy Policy.
              </span>
            </p>
            <p class="c1">
              <span class="c2 c0">
                You are advised to review this Privacy Policy periodically for any changes. Changes
                to this Privacy Policy are effective when they are posted on this page.
              </span>
            </p>
            <p class="c11">
              <span class="c2 c9"></span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Terms;
